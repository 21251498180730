import {
  CellId,
  CellType,
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  fractionalIndexMidpoint95,
} from "@hex/common";
import { useCallback } from "react";

import { useCellOrdersGetter } from "../hooks/cell/useCellOrdersGetter.js";
import { useInsertCellOp } from "../hooks/cell/useInsertCell.js";

import { SortableCell } from "./cellLayoutHelpers.js";

/**
 * Takes in a cell id and returns the order value that should be used to insert
 * a cell before/after the specified cell id (based on the "after" param). If no
 * cellId provided, then returns order after the last cell in the project.
 *
 * This has special handling for inserting after block cells.
 */
export const useGetOrderFromCellId = (): ((
  args: { cellId: CellId; after: boolean } | null,
) => string) => {
  const getCellOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
    ignoreBlockChildCells: true,
  });
  const { getCellContext, getLastCellInBlock } = useInsertCellOp();
  return useCallback(
    (cursor) => {
      const cells = getCellOrders();
      if (cells.length === 0) {
        return fractionalIndexMidpoint95(
          FRACTIONAL_INDEX_START,
          FRACTIONAL_INDEX_END,
        );
      }

      const { after, cellId } = cursor ?? {
        after: true,
        cellId: cells[cells.length - 1].id,
      };

      const index_ = cells.findIndex(({ id }) => id === cellId);
      const index = index_ === -1 ? cells.length - 1 : index_;

      const [startIndex, endIndex] = after
        ? [index, index + 1]
        : [index - 1, index];

      // typescript assumes that the array item is defined at the given index,
      // but we know that it is not, so we explicitly cast to be optionall
      // undefined.
      let startingCell = cells[startIndex] as SortableCell | undefined;
      let endingCell = cells[endIndex] as SortableCell | undefined;
      if (
        startingCell &&
        (startingCell.cellType === CellType.BLOCK ||
          startingCell.parentBlockCellId != null)
      ) {
        const lastBlockChild = getCellContext(getLastCellInBlock(startingCell));
        // note - we should always be able to find a child cell, but we fall back to parent block cell just in case.
        startingCell = lastBlockChild?.currentCell ?? startingCell;
        endingCell = lastBlockChild?.followingCell;
      }
      const startOrder =
        startingCell == null ? FRACTIONAL_INDEX_START : startingCell.order;

      const endOrder =
        endingCell == null ? FRACTIONAL_INDEX_END : endingCell.order;

      return fractionalIndexMidpoint95(startOrder, endOrder);
    },
    [getCellContext, getCellOrders, getLastCellInBlock],
  );
};
